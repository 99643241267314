import { PropsWithChildren, ReactElement, ReactNode } from 'react';

import { TextStyle } from '@legacy-components/theme';

import {
    ButtonContainer,
    Container,
    LeftContainer,
    LogoContainer,
    NavBar,
    RightContainer,
    RightContainerWrapper,
    StyledLogo,
    Title,
} from './styledComponents';

interface Props extends PropsWithChildren<{}> {
  leftButton?: ReactNode;
  rightButton?: ReactNode;
  title?: ReactNode;
  withLogo?: boolean;
  isContentPositionedHigher?: boolean;
}

const Layout = ({
  children,
  leftButton,
  rightButton,
  title,
  withLogo = false,
  isContentPositionedHigher = false,
}: Props): ReactElement => (
  <Container>
    <LeftContainer />
    <RightContainer>
      <NavBar>
        <ButtonContainer>{leftButton}</ButtonContainer>
        <ButtonContainer>{rightButton}</ButtonContainer>
      </NavBar>
      <RightContainerWrapper
        isContentPositionedHigher={isContentPositionedHigher}
      >
        {withLogo && (
          <LogoContainer>
            <StyledLogo />
          </LogoContainer>
        )}
        {title && <Title styleKey={TextStyle.HEADER_2}>{title}</Title>}
        {children}
      </RightContainerWrapper>
    </RightContainer>
  </Container>
);

export default Layout;
