import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import {
  SIGNIN_MUTATION,
  SignInData,
  SIGNUP_MUTATION,
  SignUpData,
  UserInput,
} from './mutation';
import { User } from '../account/typings';
import { Nullable } from '../common/typings';

export interface SignUpResult {
  signUp: (userInput: UserInput) => Promise<boolean>;
  inError: boolean;
  error?: ApolloError;
}

export function useSignUp(): SignUpResult {
  const [signUpMutationFn, signUpResult] = useMutation<SignUpData>(
    SIGNUP_MUTATION,
  );

  return {
    async signUp(userInput: UserInput): Promise<boolean> {
      const { data } = await signUpMutationFn({
        variables: {
          userInput,
        },
      });
      return data?.signUp || false;
    },
    inError: signUpResult.data?.signUp === false || !!signUpResult.error,
    error: signUpResult.error,
  };
}

export interface SignInOptions {
  withToken?: boolean;
}

export interface SignInResult {
  signIn: (email: string, password: string) => Promise<Nullable<User>>;
  invalidCredentials: boolean;
  inError: boolean;
  loading: boolean;
  error?: ApolloError;
}

export function useSignIn({
  withToken = false,
}: SignInOptions = {}): SignInResult {
  const [signInMutationFn, signInResult] = useMutation<SignInData>(
    SIGNIN_MUTATION,
  );

  return {
    async signIn(email: string, password: string): Promise<Nullable<User>> {
      const { data } = await signInMutationFn({
        variables: {
          email,
          password,
          withToken,
        },
      });
      if (!data || data.signInResult === null) return null;
      return data.signInResult;
    },
    invalidCredentials: signInResult.data?.signInResult === null,
    inError: !!signInResult.error,
    loading: signInResult.loading,
    error: signInResult.error,
  };
}
