import { MutationFunction, MutationResult } from '@apollo/react-common';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { User } from '../account/typings';
import { Nullable } from '../common/typings';

export type SignInVariables = {
  email: string;
  password: string;
  withToken: boolean;
};

export type SignInData = {
  signInResult: Nullable<User>;
};

export type SignInMutationResult = MutationResult<SignInData>;

export type SignInMutationFn = MutationFunction<SignInData, SignInVariables>;

export const SIGNIN_MUTATION: DocumentNode = gql`
  mutation SignIn($email: String!, $password: String!, $withToken: Boolean!) {
    signInResult: signIn(
      email: $email
      password: $password
      withToken: $withToken
    ) @connection(key: "signIn") {
      id
      email
      fullname
    }
  }
`;

export interface UserInput
  extends Pick<User, 'first_name' | 'last_name' | 'email'> {
  password: string;
  mobile_app_onboarding?: boolean;
  registration_code?: string;
}

export type SignUpVariables = {
  userInput: UserInput;
};

export type SignUpData = {
  signUp: boolean;
};

export type SignUpMutationResult = MutationResult<SignUpData>;

export type SignUpMutationFn = MutationFunction<SignUpData, SignUpVariables>;

export const SIGNUP_MUTATION: DocumentNode = gql`
  mutation SignUp($userInput: UserInput!) {
    signUp(userInput: $userInput)
  }
`;

export const UPDATE_PUBLIC_KEY_MUTATION: DocumentNode = gql`
  mutation UpdatePublicKey($publicKey: String!) {
    updateResult: updatePublicKey(publicKey: $publicKey)
  }
`;

export const REQUEST_FORGOTTEN_PASSWORD_RESET_MUTATION: DocumentNode = gql`
  mutation RequestForgottenPasswordReset($email: String!) {
    requestForgottenPasswordReset(email: $email)
  }
`;

export const RESET_FORGOTTEN_PASSWORD_MUTATION: DocumentNode = gql`
  mutation ResetForgottenPassword(
    $token: String!
    $uid: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    resetForgottenPassword(
      token: $token
      uid: $uid
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    )
  }
`;
